import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Typography } from "./Typography";
import { Img } from "../../classes/Img";
import { GatsbyImage } from "gatsby-plugin-image";
import ITLogo from "../ITLogo";
import { colors } from "../ui/colorVariables";
import { useState, useEffect, useRef } from "react";
import { getPatternImage } from "./PatternBlock";

interface Props {
  $imageHeightStyling?: string;
  $pageHeightStyling?: string;
}

const HomeImageWrapper = styled.div`
  position: absolute;
  z-index: -2;
  padding: 0;
`;

const PageImageWrapper = styled.div<Props>`
  position: absolute;
  z-index: -2;
  padding: 0;
  ${({ $pageHeightStyling }) =>
    $pageHeightStyling ? `${$pageHeightStyling}; top: -10rem` : null};
`;

const HomeImageOverlay = styled.div<Props>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  z-index: -1;
  ${({ $imageHeightStyling }) => $imageHeightStyling};
`;

const PageImageOverlay = styled.div<Props>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  z-index: -1;
  ${({ $pageHeightStyling }) => $pageHeightStyling};
  ${({ $imageHeightStyling }) => $imageHeightStyling};
`;

const HomeHeader = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  h3 {
    color: white;
    padding: 0 15vw;
    margin-top: 25%;
    text-align: center;
  }
  span {
    color: ${colors.blue.hex};
    font-family: Vujahday Script, serif;
    text-transform: uppercase;
    display: block;
  }
  @media only screen and (max-width: 1199px) {
    h3 {
      font-size: 30px !important;
    }
  }
  @media only screen and (max-width: 900px) {
    span {
      font-size: 34px !important;
    }
  }
  @media only screen and (max-width: 768px) {
    h3 {
      font-size: 22px !important;
    }
  }
`;

const HomeMobileHeader = styled(Col)<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  justify-content: center;
  ${({ $pageHeightStyling }) => $pageHeightStyling};
  h3 {
    color: white;
    padding: 0 10vw;
    text-align: center;
    margin-top: 9%;
  }
  span {
    color: ${colors.blue.hex};
    font-family: Vujahday Script, serif;
    text-transform: uppercase;
    display: block;
  }
  @media only screen and (max-width: 768px) {
    h3 {
      margin-top: 9%;
      font-size: 30px !important;
    }
    @media only screen and (max-width: 576px) {
      h3 {
        margin-top: 20%;
        font-size: 22px !important;
      }
    }
  }
`;

const PageHeroHeader = styled(Col)<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  justify-content: center;
  ${({ $imageHeightStyling }) => $imageHeightStyling};
  h1 {
    color: white;
    padding: 0 10vw;
    text-align: center;
  }
  h2 {
    color: ${colors.blue.hex};
    font-size: 50px !important;
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 40px !important;
    }
    h2 {
      font-size: 45px !important;
    }
  }
  @media only screen and (max-width: 576px) {
    h1 {
      font-size: 32px !important;
    }
    h2 {
      font-size: 30px !important;
    }
  }
`;

const HomeRow = styled(Row)<{ height: number }>`
  position: relative;
  ${({ height }) => `height: ${height}px`};
`;

const PageRow = styled(Row)<{ height: number }>`
  position: relative;
  overflow: hidden;
  height: ${({ height }) => (height ? `${height}px` : `20rem`)};
`;

const Hero = ({
  heroHeader,
  heroSubheader,
  heroImage,
  title,
  patternImage,
  isHomeHero
}: {
  heroHeader: string;
  heroSubheader: string;
  heroImage: Img;
  title: string;
  patternImage: Img;
  isHomeHero?: boolean;
}) => {
  const [viewWidth, setViewWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const imageRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const current = imageRef.current;
    const handleResize = () => {
      const rect = current?.getBoundingClientRect();
      rect ? setImageHeight(rect.height) : null;
      const winWidth = window.innerWidth;
      setViewWidth(winWidth);
    };
    const timeout = setTimeout(handleResize, 0);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(timeout);
    };
  }, [imageRef.current]);

  const imageHeightStyling = `height: ${imageHeight}px`;
  const pageHeightStyling = `height: 20rem`;
  const getHomeDisplay = () => {
    if (viewWidth > 768) {
      return (
        <HomeHeader>
          <Typography.H3>
            {heroHeader}
            <span
              style={
                isHomeHero
                  ? { fontSize: "54px", color: colors.green.hex }
                  : { fontSize: "54px" }
              }>
              {heroSubheader}
            </span>
          </Typography.H3>
          <ITLogo
            width={(viewWidth * 0.3).toString()}
            height={(viewWidth * 0.3 * 0.6116).toString()}
          />
        </HomeHeader>
      );
    } else {
      return (
        <HomeMobileHeader $imageHeightStyling={imageHeightStyling}>
          <Typography.H3>
            {heroHeader}
            <span style={isHomeHero ? { color: colors.green.hex } : {}}>
              {heroSubheader}
            </span>
          </Typography.H3>
        </HomeMobileHeader>
      );
    }
  };

  return (
    <Container fluid>
      {title === "Home" && (
        <HomeRow height={imageHeight}>
          <HomeImageWrapper ref={imageRef}>
            <GatsbyImage image={heroImage?.gatsby} alt={heroImage?.title} />
          </HomeImageWrapper>
          <HomeImageOverlay $imageHeightStyling={imageHeightStyling} />
          {getHomeDisplay()}
        </HomeRow>
      )}

      {title !== "Home" && (
        <PageRow height={imageHeight}>
          <PageImageWrapper
            ref={imageRef}
            $pageHeightStyling={
              viewWidth > 768 ? pageHeightStyling : undefined
            }>
            <GatsbyImage image={heroImage?.gatsby} alt={heroImage?.title} />
          </PageImageWrapper>
          {viewWidth > 768 ? (
            <PageImageOverlay $pageHeightStyling={pageHeightStyling} />
          ) : (
            <PageImageOverlay $imageHeightStyling={imageHeightStyling} />
          )}
          <PageHeroHeader $pageHeightStyling={pageHeightStyling}>
            <Typography.H1>{heroHeader}</Typography.H1>
            <Typography.H2>{heroSubheader}</Typography.H2>
          </PageHeroHeader>
        </PageRow>
      )}

      {((title === "Home" && viewWidth < 768) || title !== "Home") && (
        <div
          style={{
            backgroundImage: `url(${getPatternImage(patternImage, 700)})`,
            height: "3vh",
            width: "100vw",
            position: "relative",
            left: "var(--bs-gutter-x, -0.75rem)"
          }}></div>
      )}
    </Container>
  );
};

export default Hero;
