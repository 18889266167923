import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { CallToAction } from "../../classes/CallToAction";
import { GatsbyImage } from "gatsby-plugin-image";
import { Typography } from "./Typography";
import { colors } from "./colorVariables";
import DonateButton from "./DonateButton";
import { NetlifyForm } from "wmk-lib";
import { FaPaperPlane } from "react-icons/fa";
import { HyperLink } from "../../classes/Hyperlink";
import ReactMarkdown from "react-markdown";

const StyledPictureContainer = styled(Container)`
  padding: 0;
  .alt-style-text {
    color: ${colors.blue.hex};
    font-family: Vujahday Script, serif;
    font-size: 60px;
  }
  .col {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10vh 20vw;
  }
  h3 {
    a {
      color: ${colors.blue.hex};
    }
    a: hover {
      color: ${colors.lightBlue.hex};
    }
  }
  @media only screen and (max-width: 768px) {
    h3 {
      font-size: 30px !important;
    }
    span {
      font-size: 40px !important;
    }
  }
  @media only screen and (max-width: 576px) {
    h3 {
      font-size: 18px !important;
    }
    span {
      font-size: 25px !important;
    }
    .col {
      padding: 3vh 5vw;
    }
  }
`;

const StyledEmailContainer = styled(Container)`
  background-color: ${colors.lightBlue.hex};
  .outer-row {
    padding: 8vh 14vw;
  }
  form > div {
    display: flex;
    width: 100%;
  }
  .col-md-1 {
    color: white;
    display: flex;
    justify-content: center;
  }
  input {
    border-radius: 0;
  }
  button {
    border-radius: 0;
    background: ${colors.blue.hex};
    border: ${colors.blue.hex};
  }
  button:hover {
    background: ${colors.blue.hex};
    border: ${colors.blue.hex};
  }
  .container-true > .row:first-child {
    width: 100%;
  }
  @media only screen and (max-width: 992px) {
    .outer-row {
      padding: 8vh 7vw
    }
  }
  @media only screen and (max-width: 768px) {
    text-align: center;
    .row:first-child {
      flex-direction: column;
    }
    .outer-row {
      padding: 8vh 1vw
    }
    .col-md-6 {
      padding: 0 10vw;
    }
    h4 {
      font-size: 23px!important;
    }
  }
  @media only screen and (max-width: 576px) {
    h4 {
      font-size: 19px!important;
    }
`;

const BackgroundImageOverlayWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: -1;
`;

const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  // object-fit: cover;
  z-index: -2;
  @media only screen and (min-width: 1200px) {
    top: -50%;
  }
`;

const CtaBlock = ({
  backgroundImage,
  // blockId,
  buttonText,
  headline,
  link
}: CallToAction) => {
  const headlineText = headline?.internal?.content;
  const modifiedHeadline = headlineText ? headlineText.split("*") : "";
  const displayHeadline =
    Array.isArray(modifiedHeadline) && modifiedHeadline.length ? (
      <Typography.H3 style={{ color: "white" }}>
        <ReactMarkdown
          children={headlineText}
          components={{
            em: ({ node, ...props }) => (
              <span className="alt-style-text" {...props} />
            )
          }}
        />
      </Typography.H3>
    ) : (
      <ReactMarkdown children={headlineText} />
    );

  const getPictureDisplay = () => {
    return (
      <StyledPictureContainer fluid>
        <Row style={{ width: "100%", margin: 0 }}>
          <Col>
            {displayHeadline}
            {link instanceof HyperLink ? (
              <DonateButton to={link.to ? link.to : ""}>
                {buttonText}
              </DonateButton>
            ) : (
              <DonateButton to={link.url} target={link.url}>
                {buttonText}
              </DonateButton>
            )}
            <BackgroundImageWrapper>
              <GatsbyImage
                image={backgroundImage?.gatsby}
                alt={backgroundImage?.alt}
              />
            </BackgroundImageWrapper>
            <BackgroundImageOverlayWrapper />
          </Col>
        </Row>
      </StyledPictureContainer>
    );
  };

  const getEmailDisplay = () => {
    return (
      <StyledEmailContainer fluid>
        <Row className="outer-row">
          <Col md={1}>
            <Typography.H5 style={{ fontSize: "48px" }}>
              <FaPaperPlane />
            </Typography.H5>
          </Col>
          <Col md={6}>
            <Typography.H4>{headline?.internal?.content}</Typography.H4>
          </Col>
          <Col>
            <NetlifyForm
              config={{
                thankYou: () => (
                  <>
                    <h3>Thanks for signing up!</h3>
                  </>
                ),
                name: `email_subscription_form`,
                postUrl: `/`,
                consoleMessage: true,
                submit: buttonText
              }}
              fields={[{ as: `email` }]}
            />
          </Col>
        </Row>
      </StyledEmailContainer>
    );
  };

  const getDisplay = () => {
    if (backgroundImage && backgroundImage?.gatsby) {
      return getPictureDisplay();
    } else {
      return getEmailDisplay();
    }
  };
  return getDisplay();
};

export default CtaBlock;
