import * as React from "react";

const HeartIcon = ({ width = "56", height = "54.223" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 56 54.223">
      <defs>
        <clipPath id="clip-path">
          <rect width="56" height="54.223" fill="none" />
        </clipPath>
      </defs>
      <g id="Icon_heart" data-name="Icon heart" clipPath="url(#clip-path)">
        <g
          id="Path_14"
          data-name="Path 14"
          transform="translate(-2 -3)"
          fill="none">
          <path
            d="M30,57.223l-4.06-3.9C11.52,39.523,2,30.422,2,19.252,2,10.151,8.776,3,17.4,3A16.424,16.424,0,0,1,30,9.176,16.424,16.424,0,0,1,42.6,3C51.224,3,58,10.151,58,19.252c0,11.17-9.52,20.271-23.94,34.1Z"
            stroke="none"
          />
          <path
            d="M 30.00526237487793 54.4547004699707 L 32.78620910644531 51.80262756347656 C 39.77753067016602 45.09782791137695 45.81544876098633 39.30735778808594 49.88722991943359 34.04019927978516 C 54.11474990844727 28.57156753540039 56 24.01076698303223 56 19.25214767456055 C 56 11.26028823852539 50.11402130126953 4.999998092651367 42.59999847412109 4.999998092651367 C 38.42935943603516 4.999998092651367 34.30094146728516 7.030558109283447 31.55648040771484 10.43175792694092 L 30 12.36068820953369 L 28.44351959228516 10.43175792694092 C 25.69906044006348 7.030558109283447 21.57064056396484 4.999998092651367 17.39999961853027 4.999998092651367 C 9.885979652404785 4.999998092651367 4 11.26028823852539 4 19.25214767456055 C 4 24.01901817321777 5.891600131988525 28.58688735961914 10.1333703994751 34.06313705444336 C 14.21848011016846 39.33713912963867 20.27474975585938 45.13282775878906 27.28731918334961 51.84365844726562 L 27.32559967041016 51.88029861450195 L 30.00526237487793 54.4547004699707 M 30 57.22307968139648 L 25.94000053405762 53.32255935668945 C 11.52000045776367 39.52300643920898 2 30.42179870605469 2 19.25214767456055 C 2 10.15094757080078 8.776000022888184 2.999997854232788 17.39999961853027 2.999997854232788 C 22.27199935913086 2.999997854232788 26.94799995422363 5.393497943878174 30 9.175817489624023 C 33.05199813842773 5.393497943878174 37.72800064086914 2.999997854232788 42.59999847412109 2.999997854232788 C 51.2239990234375 2.999997854232788 58 10.15094757080078 58 19.25214767456055 C 58 30.42179870605469 48.47999954223633 39.52300643920898 34.06000137329102 53.35211944580078 L 30 57.22307968139648 Z"
            stroke="none"
            fill="#00abbe"
          />
        </g>
      </g>
    </svg>
  );
};

export default HeartIcon;
