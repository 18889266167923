import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { options } from "./RichTextOptions";
import * as React from "react";
import get from "lodash/get";

export const RichTextReact = ({ content }) => {
  const { json } = content;
  if (get(json, "content[0].references.internal.type") === "ContentfulBlockPattern") {
    json.content[0].references.isFirst = true
  }

  return <>{documentToReactComponents(json, options)}</>;
};
