import get from "lodash/get";
import * as React from "react";
import { CallToAction, callToAction } from "../../classes/CallToAction";
import { Cards, cards } from "../../classes/Card";
import { Child, Children } from "../../classes/Child";
import { IconsBlock, iconsBlock } from "../../classes/IconsBlock";
import { ImageCopy, imageCopy } from "../../classes/ImageCopy";
import { Img } from "../../classes/Img";
import { PageCards, pageCards } from "../../classes/PageCards";
import { PatternBlock, patternBlock } from "../../classes/PatternBlock";
import { SingleColText, singleColTextQuery } from "../../classes/SingleColText";
import ChildCardDisplay from "../ui/ChildCardDisplay";
import CtaBlock from "../ui/CtaBlock";
import IconsBlockComp from "../ui/IconsBlock";
import ImageCopyComp from "../ui/ImageCopy";
import PageCardsComp from "../ui/PageCards";
import { PatternBlockComp } from "../ui/PatternBlock";
import SingleColTextComp from "../ui/SingleColTextComp";

interface childrenCards {
  children: Children;
}

export const NullComponent = () => <></>;

export class EmbeddedBlock {
  props:
    | childrenCards
    | undefined
    | iconsBlock
    | pageCards
    | callToAction
    | cards
    | imageCopy
    | patternBlock
    | SingleColText;
  Comp: Function;
  blockId: string;
  constructor(node: unknown) {
    const data = get(node, `references`);
    const id = get(data, `blockId`);
    this.props = undefined;
    this.Comp = NullComponent;
    this.blockId = id;
    switch (true) {
      case get(data, `internal.type`) === "ContentfulBlockPageCards":
        const cardPage = new PageCards(data);
        this.setProps({
          cards: get(cardPage, `cards`),
          header: get(cardPage, `header`),
          title: get(cardPage, `title`),
          displayAs: get(cardPage, `displayAs`),
          blockId: id
        });
        this.setComp(PageCardsComp);
        break;

      case get(data, `internal.type`) === "ContentfulBlockIcons":
        const iconBlock = new IconsBlock(data);
        this.setProps({
          blockId: id,
          firstIconId: get(iconBlock, `firstIconId`),
          firstIconText: get(iconBlock, `firstIconText`),
          firstIconTitle: get(iconBlock, `firstIconTitle`),
          headerContainer: get(iconBlock, `headerContainer`),
          image: get(iconBlock, `image`),
          secondIconId: get(iconBlock, `secondIconId`),
          secondIconText: get(iconBlock, `secondIconText`),
          secondIconTitle: get(iconBlock, `secondIconTitle`),
          thirdIconId: get(iconBlock, `thirdIconId`),
          thirdIconText: get(iconBlock, `thirdIconText`),
          thirdIconTitle: get(iconBlock, `thirdIconTitle`),
          title: get(iconBlock, `title`)
        });
        this.setComp(IconsBlockComp);
        break;

      case get(data, `internal.type`) === "ContentfulBlockPattern":
        const patternBlock = new PatternBlock(data);
        const patternImg = new Img(get(data, "patternImage"));
        this.setProps({
          blockId: id,
          link: get(patternBlock, `link`),
          linkText: get(patternBlock, `linkText`),
          media: get(patternBlock, `media`),
          text: get(patternBlock, `text.text`),
          title: get(patternBlock, `title`),
          patternImage: patternImg,
          isFirst: get(patternBlock, "isFirst"),
          videoLink: "videoLink" in patternBlock ? patternBlock.videoLink : ""
        });
        this.setComp(PatternBlockComp);
        break;

      case data?.internal?.type === "ContentfulBlockCards":
        const cards = new Cards(data);
        this.setProps({
          cards: cards?.cards,
          header: cards?.header,
          title: cards?.title,
          displayAs: cards?.displayAs,
          blockId: id
        });
        this.setComp(PageCardsComp);
        break;

      case get(data, `internal.type`) === "ContentfulBlockCallToAction":
        const cta = new CallToAction(data);
        this.setProps({
          backgroundImage: cta.backgroundImage,
          buttonText: cta.buttonText,
          headline: cta.headline,
          link: cta.link,
          blockId: id
        });
        this.setComp(CtaBlock);
        break;

      case get(data, `internal.type`) === "ContentfulBlockImageCopy":
        const imageCopy = new ImageCopy(data);
        this.setProps({
          header: get(imageCopy, `header`),
          image: get(imageCopy, `image`),
          imagePosition: get(imageCopy, `imagePosition`),
          link: get(imageCopy, `link`),
          text: get(imageCopy, `text`),
          subheader: get(imageCopy, `subheader`),
          blockId: id
        });
        this.setComp(ImageCopyComp);
        break;
      case data?.internal?.type === "ContentfulBlockChildrenCards":
        const childrenCards = data.contentfulchildren?.map(
          (child) => new Child(child)
        );
        this.setProps({
          children: childrenCards
        });
        this.setComp(ChildCardDisplay);
        break;

      case data?.internal?.type === "ContentfulBlockSingleColumnTextBlock":
        const singleColText = new SingleColText(data);
        this.setProps({
          id: singleColText.id,
          header: singleColText.header,
          subheader: singleColText.subheader,
          text: singleColText.text
        });
        this.setComp(SingleColTextComp);
        break;
      default:
        console.log(`no block component found for data:`, node);
    }
  }
  render() {
    const Jsx = this.Comp;
    const props = this.props;
    return <Jsx {...props} />;
  }
  setProps(
    props:
      | undefined
      | iconsBlock
      | pageCards
      | callToAction
      | cards
      | imageCopy
      | patternBlock
      | childrenCards
      | SingleColText
  ) {
    this.props = props ? props : undefined;
  }
  setComp(Comp: Function) {
    this.Comp = Comp ? Comp : NullComponent;
  }
}

export default EmbeddedBlock;
