import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Child } from "../../classes/Child";
import { useState, useEffect } from "react";
import ChildCard from "./ChildCard";
import { cloneDeep } from "lodash";
import { Typography } from "./Typography";
import styled from "styled-components";
import { colors } from "./colorVariables";

const StyledContainer = styled(Container)`
  margin-top: 5vh;
  margin-bottom: 5vh;
`;

const ButtonCol = styled(Col)`
  p {
    font-weight: 400 !important;
    font-size: 14px !important;
    text-transform: uppercase;
    margin: 0;
    color: ${colors.blue.hex};
  }
  button {
    border: none;
    background: none;
    margin: 0 1vw;
    p {
      color: ${colors.gray.hex};
    }
  }
`;

const ChildCardDisplay = ({ children }: { children: Child[] }) => {
  // const data = useStaticQuery(graphql`
  //   {
  //     children: allContentfulBlockChildCard {
  //       edges {
  //         node {
  //           ...NodeBlockChildCardFields
  //         }
  //       }
  //     }
  //   }
  // `);

  // console.log("data.children.edges", data.children.edges);
  // const children = new Children(data.children.edges);
  // console.log("children", children);
  const [displayChildren, setDisplayChildren] = useState(children);
  const [viewWidth, setViewWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      const winWidth = window.innerWidth;
      setViewWidth(winWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const childrenCopy = cloneDeep(children);

  const sortByInput = (sortParam: string) => {
    setDisplayChildren(
      childrenCopy.sort((a: Child, b: Child) => {
        if (a[sortParam as keyof Child] < b[sortParam as keyof Child]) {
          return -1;
        } else if (a[sortParam as keyof Child] > b[sortParam as keyof Child]) {
          return 1;
        } else {
          return 0;
        }
      })
    );
  };

  const modifyHighToLow = () => {
    setDisplayChildren(
      childrenCopy.sort((a: Child, b: Child) => {
        if (a.age > b.age) {
          return -1;
        } else if (a.age < b.age) {
          return 1;
        } else {
          return 0;
        }
      })
    );
  };

  return (
    <>
      <StyledContainer>
        {viewWidth > 768 && (
          <Row style={{ margin: "1.5rem 0" }}>
            <ButtonCol>
              <Typography.Button style={{ display: "inline-block" }}>
                Sort By:
              </Typography.Button>
              <button
                onClick={() => {
                  sortByInput("age");
                }}>
                <Typography.Button>Age(low to high)</Typography.Button>
              </button>
              <button
                onClick={() => {
                  modifyHighToLow();
                }}>
                <Typography.Button>Age(High to low)</Typography.Button>
              </button>
              <button
                onClick={() => {
                  sortByInput("dateAdded");
                }}>
                <Typography.Button>Longest waiting</Typography.Button>
              </button>
              <button
                onClick={() => {
                  sortByInput("birthMonth");
                }}>
                <Typography.Button>Birth month</Typography.Button>
              </button>
            </ButtonCol>
          </Row>
        )}
        <Row>
          {Array.isArray(displayChildren) &&
            displayChildren.map((child: Child, i) => {
              return (
                <Col
                  md={4}
                  lg={3}
                  key={child.name + i}
                  style={{ marginBottom: "5vh" }}>
                  <ChildCard
                    name={child.name}
                    age={child.age}
                    link={child.sponsorLink}
                    image={child.image}
                  />
                </Col>
              );
            })}
        </Row>
      </StyledContainer>
    </>
  );
};

export default ChildCardDisplay;
