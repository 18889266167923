import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { colors } from "./colorVariables";
import { Typography } from "./Typography";
import { useState, useEffect } from "react";

const StyledTextCol = styled(Col)<{ width: number }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  ${({ width }) => (width > 778 ? "" : "text-align: center")};
  h3 {
    color: ${colors.blue.hex};
    margin: 1rem 0;
  }
  .subheader {
    color: ${colors.blue.hex};
    p {
      margin: 0;
      font-size: 2rem !important;
    }
  }
  p {
    a {
      color: ${colors.darkBlue.hex};
    }
    a: hover {
      color: ${colors.blue.hex};
    }
  }
`;

const ContainerWrap = styled(Container)`
  margin: 5vh 0;
  justify-content: center;
  display: flex;
  @media only screen and (max-width: 768px) {
    margin: 5vh 0;
  }
  .row {
    position: relative;
    width: 70%;
  }
`;

const SingleColTextComp = ({
  header,
  subheader,
  text
}: {
  header?: string;
  subheader?: string;
  text: { text: string };
}) => {
  const [windowWidth, setWindowWidth] = useState(1201);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setWindowWidth]);

  return (
    <>
      <ContainerWrap fluid>
        <Row>
          <StyledTextCol width={windowWidth}>
            {subheader && (
              <div className="subheader">
                <Typography.Eyebrow>{subheader}</Typography.Eyebrow>
              </div>
            )}
            {header && <Typography.H3>{header}</Typography.H3>}
            <Typography.P style={{ marginBottom: "1rem" }}>
              {
                <ReactMarkdown
                  components={{
                    a: ({ node, ...props }) => {
                      return (
                        <a
                          target={
                            node.properties?.href &&
                            typeof node.properties?.href === "string" &&
                            node.properties?.href.includes("http") &&
                            !node.properties?.href.includes(
                              "indiatransformed.org"
                            )
                              ? "_blank"
                              : "_self"
                          }
                          {...props}
                        />
                      );
                    }
                  }}
                  children={text?.text}
                />
              }
            </Typography.P>
          </StyledTextCol>
        </Row>
      </ContainerWrap>
    </>
  );
};

export default SingleColTextComp;
