import * as React from "react";

const PersonIcon = ({ width="56.302", height="58" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 56.302 58">
      <path
        id="Path_1868"
        data-name="Path 1868"
        d="M55.35,13.2A108.332,108.332,0,0,1,29.923,16,108.332,108.332,0,0,1,4.5,13.2L3,18.8a112.7,112.7,0,0,0,17.949,2.8V58h5.983V41.2h5.983V58H38.9V21.6a112.7,112.7,0,0,0,17.949-2.8Zm-25.427,0a5.818,5.818,0,0,0,5.983-5.6A5.818,5.818,0,0,0,29.923,2,5.818,5.818,0,0,0,23.94,7.6,5.818,5.818,0,0,0,29.923,13.2Z"
        transform="translate(-1.772 -1)"
        fill="none"
        stroke="#00abbe"
        strokeWidth="2"
      />
    </svg>
  );
};

export default PersonIcon;
