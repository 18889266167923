import { page } from "./Page";
import { Card, card } from "./Card";
import { donationCard } from "./DonationCard";

export interface pageCards {
  blockId: string;
  displayAs: string;
  header: string;
  pages: page[] | donationCard[] | page;
  title: string;
  cards: card[];
}

export class PageCards {
  blockId: string;
  displayAs: string;
  header: string;
  title: string;
  cards: Card[];
  constructor(node: pageCards) {
    const _pages = node.pages;
    const cards = Array.isArray(_pages)
      ? _pages.map((page) => {
          const cardData = {
            title: page.title,
            blockId: "blockId" in page ? page?.blockId : page?.slug,
            text: "text" in page ? page?.text : { text: page?.metaDescription?.metaDescription },
            link: "link" in page ? page?.link : page,
            price: "price" in page ? page?.price : undefined
          };
          return new Card(cardData);
        })
      : [
          new Card({
            title: _pages.title,
            blockId: _pages.slug,
            text: { text: _pages.metaDescription },
            link: _pages
          })
        ];
    this.blockId = node.blockId;
    this.cards = cards;
    this.header = node.header;
    this.title = node.title;
    this.displayAs = node.displayAs;
  }
}
