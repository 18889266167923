import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Card } from "../../classes/Card";
import { Typography } from "./Typography";
import CardComp from "./Card";
import { colors } from "./colorVariables";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import Button from "./MainButton";
import { GatsbyImage } from "gatsby-plugin-image";
import ImageCarouselWrap from "../CarouselStyles";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const GridContainer = styled(Container)`
  width: 70% !important;
  margin-bottom: 5vh;
  .card-column {
    margin: 3% 0;
  }
`;

const MoblieGridContainer = styled(Container)`
  width: 75% !important;
  margin-top: 5vh;
  h3 {
    margin-bottom: 2rem;
  }
  .card-column {
    margin: 6.5% 0;
  }
`;

const CarouselContainer = styled(Container)`
  margin: 1rem 0;
  .carousel-card-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
  }
  .carousel-img-col {
    min-height: 50vh;
    display: flex;
    position: relative;
  }
`;

const CarouselCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8%;
  background: #f4f4f4;
  position: absolute;
  top: 20%;
  width: 120%;
  z-index: 1;
  max-height: calc(80% - 4rem);
  overflow: scroll;
  @media only screen and (max-width: 1300px) {
    top: 10%;
    h4 {
      margin-bottom: 1rem;
    }
  }
`;

const CarouselHeader = styled.div`
  padding: 0.5rem 1.5rem;
  // background: #454545;
  display: inline-block;
  // position: absolute;
  color: ${colors.blue.hex};
  z-index: 1;
  left: 0;
  top: 0;
  text-transform: uppercase;
  p {
    margin: 0;
    // font-size: 25px !important;
  }
`;

const PageCardsComp = ({
  displayAs,
  header,
  title,
  cards
}: {
  displayAs: string;
  header: string;
  title: string;
  cards: Card[];
}) => {
  const [windowWidth, setWindowWidth] = useState(1201);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setWindowWidth]);

  const cardsDisplay = cards.map((card, i) => {
    return (
      <Col
        sm={12}
        md={6}
        lg={4}
        xxl={4}
        key={card.blockId + i}
        className="card-column">
        <CardComp
          blockId={card.blockId}
          link={card.link}
          text={card.text}
          title={card.title}
          images={card.images}
          largeText={card.largeText}
        />
      </Col>
    );
  });

  const cardDesktopDisplay = (
    <GridContainer fluid>
      <Row>
        <Col style={{ margin: "5vh 0 0" }}>
          <Typography.Eyebrow style={{ color: colors.blue.hex, fontSize: "2rem" }}>
            {title}
          </Typography.Eyebrow>
          <Typography.H3>{header}</Typography.H3>
        </Col>
      </Row>
      <Row>{cardsDisplay}</Row>
    </GridContainer>
  );

  const cardMobileDisplay = (
    <MoblieGridContainer fluid>
      <Row>
        <Col style={{ margin: "5vh 0 0" }}>
          <Typography.Eyebrow style={{ color: colors.blue.hex }}>
            {title}
          </Typography.Eyebrow>
          <Typography.H3>{header}</Typography.H3>
        </Col>
      </Row>
      <Row>{cardsDisplay}</Row>
    </MoblieGridContainer>
  );

  const getImageCarousel = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true
    };
    return (
      <Container fluid style={{ width: "70%" }}>
        <ImageCarouselWrap>
          <CarouselHeader>
            <Typography.Eyebrow style={{fontSize: "2rem" }}>{header}</Typography.Eyebrow>
          </CarouselHeader>
          <Slider {...settings}>
            {cards.map((card, i) => {
              return card ? (
                <CarouselContainer fluid key={card.blockId + i}>
                  <Row>
                    <Col className="carousel-card-col">
                      <CarouselCard>
                        <Typography.H4>{card.title}</Typography.H4>
                        <Typography.P><ReactMarkdown children={card?.text?.text} /></Typography.P>
                      </CarouselCard>
                      <Button to={"to" in card.link ? card.link.to as string : ""}>
                        {"title" in card.link ? card.link.title : "Learn More"}
                      </Button>
                    </Col>
                    <Col className="carousel-img-col">
                      {card?.images?.gatsby ? (
                        <GatsbyImage
                          image={card.images.gatsby}
                          alt={card.images.title}></GatsbyImage>
                      ) : null}
                    </Col>
                  </Row>
                </CarouselContainer>
              ) : null;
            })}
          </Slider>
        </ImageCarouselWrap>
      </Container>
    );
  };

  const getDisplay = () => {
    if (displayAs === "Carousel" && windowWidth > 768) {
      return getImageCarousel();
    } else if (windowWidth > 768) {
      return cardDesktopDisplay;
    } else {
      return cardMobileDisplay;
    }
  };

  return <>{getDisplay()}</>;
};

export default PageCardsComp;
