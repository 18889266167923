import { ContentfulImageQuery, Img } from "wmk-image";

export interface imageCopy {
  blockId: string;
  header: string;
  image: ContentfulImageQuery;
  imagePosition: string;
  link: {
    description: { description: string };
    image: ContentfulImageQuery;
    linkId: string;
    linkText: string;
    url: string;
    pdfLink: {
      file: {
        fileName: string;
        url: string;
      };
    };
  };
  text: { text: string };
  subheader: string;
}

export class ImageCopy {
  blockId: string;
  header: string;
  image: Img;
  imagePosition: string;
  link: {
    description: { description: string };
    image: Img | null;
    linkId: string;
    linkText: string;
    url: string;
    pdfLink: {
      file: {
        fileName: string;
        url: string;
      };
    };
  };
  text: { text: string };
  subheader: string;
  constructor(node: imageCopy) {
    const _node = { ...node };
    const modifiedLink = { ..._node.link, image: _node.link ? new Img(_node.link.image) : null };
    this.image = new Img(_node.image);
    this.blockId = _node.blockId;
    this.header = _node.header;
    this.imagePosition = _node.imagePosition;
    this.link = modifiedLink;
    this.text = _node.text;
    this.subheader = _node.subheader;
  }
}
