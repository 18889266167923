import { LinkQuery } from "../fragments/NodeLinkFields";
import { HyperLink, hyperlink } from "./Hyperlink";
import { gatsbyImg, Img } from "./Img";

export interface card {
  blockId: string;
  link:
    | {
        title: string;
        parentPath: string;
        slug: string;
        heroImage: {
          gatsbyImageData: gatsbyImg["gatsbyImageData"];
          title: string;
        };
      }
    | LinkQuery;
  text: { text: string };
  title: string;
  images?: gatsbyImg[];
  price?: string;
}

export interface cards {
  blockId: string;
  cards: card[];
  header: string;
  title: string;
}

export class Card {
  blockId: string;
  link: HyperLink | LinkQuery;
  text: { text: string };
  largeText?: string;
  title: string;
  images?: Img;
  constructor(node: card) {
    this.blockId = node.blockId;
    this.link = node?.link && "url" in node?.link ? node?.link : new HyperLink(node?.link);
    this.text = node.text;
    this.title = node.title;
    this.images =
      node?.link && "heroImage" in node.link ? new Img(node.link.heroImage) : undefined;
    this.largeText = node.price;
  }
}

export class Cards {
  blockId: string;
  cards: Card[];
  header: string;
  title: string;
  constructor(node: cards) {
    const _cards = node.cards;
    const cards = _cards.map((card) => {
      return new Card(card);
    });
    this.blockId = node.blockId;
    this.cards = cards;
    this.header = node.header;
    this.title = node.title;
  }
}
