import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ImageCopy } from "../../classes/ImageCopy";
import { Typography } from "./Typography";
import { WmkLink } from "wmk-lib";
import styled from "styled-components";
import { colors } from "./colorVariables";
import { useState, useEffect } from "react";
import { WmkImage } from "wmk-image";
import ReactMarkdown from "react-markdown";

const StyledTextCol = styled(Col)`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  h3 {
    color: ${colors.blue.hex};
    margin: 1rem 0;
  }
  .subheader {
    color: ${colors.blue.hex};
    p {
      margin: 0;
      font-size: 2rem !important;
    }
  }
  .link-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: ${colors.orange.hex};
    width: 50%;
    padding: 0.5rem 0.5rem;
    min-height: 2rem;
    min-width: 8rem;
    text-decoration: none;
    color: white;
    transition: all 0.2s ease;
    p {
      margin: 0;
      text-transform: uppercase;
    }
    &:hover {
      box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.3);
    }
  }
  p {
    a {
      color: ${colors.darkBlue.hex};
    }
    a: hover {
      color: ${colors.blue.hex};
    }
  }
`;

const MobileCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
  h3 {
    color: ${colors.blue.hex};
    margin: 2rem 0;
    text-align: center;
  }
  .subheader {
    color: ${colors.blue.hex};
    min-height: 2.5rem;
    min-width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: absolute;
    top: -6vh;
    z-index: 1;
    p {
      margin: 0;
      font-size: 1.5rem !important;
    }
  }
  .link-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: ${colors.orange.hex};
    width: 50%;
    padding: 0.5rem 0.5rem;
    min-height: 2rem;
    min-width: 8rem;
    text-decoration: none;
    color: white;
    transition: all 0.2s ease;
    p {
      margin: 0;
      text-transform: uppercase;
    }
    &:hover {
      box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.3);
    }
  }
`;

const ContainerWrap = styled(Container)`
  margin: 5vh 0;
  justify-content: center;
  display: flex;
  @media only screen and (max-width: 768px) {
    margin: 7vh 0 5vh;
  }
`;

const ImageCopyComp = ({
  header,
  image,
  imagePosition,
  link,
  text,
  subheader
}: ImageCopy) => {
  const [windowWidth, setWindowWidth] = useState(1201);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setWindowWidth]);

  const displayLink = link?.pdfLink?.file?.url
    ? link?.pdfLink?.file?.url
    : link?.url;

  const textColumn = (
    <StyledTextCol>
      <div className="subheader">
        <Typography.Eyebrow>{subheader}</Typography.Eyebrow>
      </div>
      <Typography.H3>{header}</Typography.H3>
      <Typography.P style={{ marginBottom: "1rem" }}>
        {<ReactMarkdown children={text?.text} />}
      </Typography.P>
      {displayLink && (
        <WmkLink to={displayLink} target={displayLink} className="link-wrap">
          <Typography.P>{link?.linkText}</Typography.P>
        </WmkLink>
      )}
    </StyledTextCol>
  );
  const imageColumn = (
    <Col>
      <WmkImage image={image} />
      {/* <GatsbyImage image={image?.gatsby} alt={image?.title} /> */}
    </Col>
  );
  const columnDisplay =
    imagePosition === "Left" ? (
      <>
        {imageColumn}
        {textColumn}
      </>
    ) : (
      <>
        {textColumn}
        {imageColumn}
      </>
    );

  const mobileDisplay = (
    <MobileCol>
      <div className="subheader">
        <Typography.Eyebrow>{subheader}</Typography.Eyebrow>
      </div>
      <WmkImage image={image} />
      {/* <GatsbyImage image={image?.gatsby} alt={image?.title} /> */}
      <Typography.H3>{header}</Typography.H3>
      <Typography.P style={{ marginBottom: "2rem", textAlign: "center" }}>
        {text?.text}
      </Typography.P>
      {displayLink && (
        <WmkLink to={displayLink} className="link-wrap">
          <Typography.P>{link?.linkText}</Typography.P>
        </WmkLink>
      )}
    </MobileCol>
  );

  const getDisplay = () => {
    if (windowWidth > 768) {
      return columnDisplay;
    } else {
      return mobileDisplay;
    }
  };
  return (
    <>
      <ContainerWrap fluid>
        <Row style={{ position: "relative", width: "70%" }}>{getDisplay()}</Row>
      </ContainerWrap>
    </>
  );
};

export default ImageCopyComp;
