import * as React from "react";
import { Card } from "../../classes/Card";
import { Typography } from "./Typography";
import { WmkLink } from "wmk-lib";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { colors } from "./colorVariables";
import { Col, Container, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

const GridCardWrapper = styled.div`
  background: ${colors.lightGray.hex};
  width: 100%;
  height: 100%;
  padding: 1.5rem 0.5rem;
  box-shadow: 0px 1px 5px ${colors.gray.hex};
  .container-fluid {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  h5 {
    margin-bottom: 1rem;
  }
  a {
    text-decoration: none;
    transition: all 0.3s ease;
    color: ${colors.blue.hex};
  }
  a:hover {
    transition: all 0.3s ease;
    color: rgba(${colors.blue.rgb}, 0.5);
  }
  ol,
  ul {
    padding-left: 1rem;
  }
`;

const PictureCardWrapper = styled.div`
  background: ${colors.lightGray.hex};
  width: 90%;
  height: 100%;
  padding: 0 0rem 0.5rem;
  .container-fluid {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  h5 {
    margin-bottom: 1rem;
  }
  a {
    text-decoration: none;
    transition: all 0.3s ease;
    color: ${colors.blue.hex};
  }
  a:hover {
    transition: all 0.3s ease;
    color: rgba(${colors.blue.rgb}, 0.5);
    font-size: 18px;
  }
  ol,
  ul {
    padding-left: 1rem;
  }
`;

const CardComp = ({ link, text, title, images, largeText }: Card) => {
  const linkLocation = "url" in link ? link.url : link.to;
  const linkText = "linkText" in link ? link.linkText : "Learn More";
  const getCardDisplay = () => {
    if (!images?.gatsby) {
      const gridCardDisplay = (
        <GridCardWrapper>
          <Container fluid>
            <Row>
              <Col>
                <Typography.H5>{title}</Typography.H5>
                {largeText && (
                  <Typography.H3 style={{ fontWeight: 300 }}>
                    {largeText}
                  </Typography.H3>
                )}
                <Typography.P style={{ marginBottom: "1rem" }}>
                  {text?.text ? (
                    <ReactMarkdown
                      components={{
                        a: ({ node, ...props }) => {
                          return (
                            <a
                              target={
                                node.properties?.href &&
                                typeof node.properties?.href === "string" &&
                                node.properties?.href.includes("http") &&
                                !node.properties?.href.includes(
                                  "indiatransformed.org"
                                )
                                  ? "_blank"
                                  : "_self"
                              }
                              {...props}
                            />
                          );
                        }
                      }}
                      children={text.text}
                    />
                  ) : (
                    ""
                  )}
                </Typography.P>
                {linkLocation && linkText && (
                  <WmkLink
                    to={linkLocation}
                    target={
                      linkLocation.includes("http") &&
                      !linkLocation.includes("indiatransformed.org")
                        ? "_blank"
                        : "_self"
                    }>
                    {linkText}
                    {" >"}
                  </WmkLink>
                )}
              </Col>
            </Row>
          </Container>
        </GridCardWrapper>
      );
      return gridCardDisplay;
    } else if (images) {
      const imageCardDisplay = (
        <PictureCardWrapper>
          <Container fluid style={{ width: "95%" }}>
            <Row>
              <Col>
                <GatsbyImage
                  image={images?.gatsby}
                  alt={images?.title}
                  style={{ position: "relative", top: "-13%", width: "135%" }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Typography.H4>{title}</Typography.H4>
                <Typography.P style={{ marginBottom: "1rem" }}>
                  {text?.text ? (
                    <ReactMarkdown
                      components={{
                        a: ({ node, ...props }) => {
                          return (
                            <a
                              target={
                                node.properties?.href &&
                                typeof node.properties?.href === "string" &&
                                node.properties?.href.includes("http") &&
                                !node.properties?.href.includes(
                                  "indiatransformed.org"
                                )
                                  ? "_blank"
                                  : "_self"
                              }
                              {...props}
                            />
                          );
                        }
                      }}
                      children={text.text}
                    />
                  ) : (
                    ""
                  )}
                </Typography.P>
              </Col>
            </Row>
            <Row style={{ margin: "auto auto 0 0" }}>
              <Col style={{ marginBottom: "1rem", paddingLeft: 0 }}>
                {linkLocation && linkText && (
                  <WmkLink
                    to={linkLocation}
                    target={
                      linkLocation.includes("http") &&
                      !linkLocation.includes("indiatransformed.org")
                        ? "_blank"
                        : "_self"
                    }>
                    {linkText}
                    {" >"}
                  </WmkLink>
                )}
              </Col>
            </Row>
          </Container>
        </PictureCardWrapper>
      );
      return imageCardDisplay;
    }
  };
  return <>{getCardDisplay()}</>;
};

export default CardComp;
