import styled from "styled-components";
import { colors } from "./ui/colorVariables";

const ImageCarouselWrap = styled.div`
  @charset 'UTF-8';
  margin: 10vh 0;
  /* Slider */
  .slick-loading .slick-list {
    background: #fff url("./ajax-loader.gif") center center no-repeat;
  }

  /* Arrows */
  .slick-prev,
  .slick-next {
    font-size: 3rem;
    line-height: 0;

    position: absolute;
    top: 7%;

    // display: block;

    width: 5rem;
    height: 2rem;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
  }
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 3rem;
    line-height: 1;
    position: absolute;
    top: -20%;

    opacity: 0.75;
    color: ${colors.blue.hex};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: -4rem;
  }
  [dir="rtl"] .slick-prev {
    right: -4rem;
    left: auto;
  }
  .slick-prev:before {
    content: "➔";
    transform: rotate(180deg);
  }
  [dir="rtl"] .slick-prev:before {
    content: "→";
  }

  .slick-next {
    left: 10rem;
    z-index: 2;
  }
  [dir="rtl"] .slick-next {
    left: auto;
    right: 10rem;
  }
  .slick-next:before {
    content: "➔";
  }
  [dir="rtl"] .slick-next:before {
    content: "←";
  }

  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    top: 5%;
    left: 0;

    display: flex !important;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
  }
  .slick-dots li {
    position: relative;

    display: inline-block;

    width: 1rem;
    height: 2rem;
    margin: 0 3px;
    padding: 0;

    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-family: "slick";
    font-size: 2rem;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: "○";
    text-align: center;

    color: ${colors.blue.hex};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    content: "●";
    color: ${colors.blue.hex};
  }
`;

export default ImageCarouselWrap;
