import { HyperLink } from "./Hyperlink";
import { gatsbyImg, Img } from "./Img";

export interface callToAction {
  backgroundImage: gatsbyImg;
  blockId: string;
  buttonText: string;
  headline: { internal: { content: string } };
  link: { url: string } | { parentPath: string; slug: string; title: string };
  // link: {
  //   description: { description: string };
  //   image: gatsbyImg;
  //   linkId: string;
  //   linkText: string;
  //   url: string;
  // };
}

export class CallToAction {
  backgroundImage: Img;
  blockId: string;
  buttonText: string;
  headline: { internal: { content: string } };
  link: { url: string } | HyperLink;
  // link: {
  //   description: { description: string };
  //   image: gatsbyImg;
  //   linkId: string;
  //   linkText: string;
  //   url: string;
  // };
  constructor(node: callToAction) {
    const _node = {...node}
    this.backgroundImage = new Img(_node.backgroundImage);
    this.blockId = _node.blockId;
    this.buttonText = _node.buttonText;
    this.headline = _node.headline;
    this.link = _node.link && "url" in _node.link ? _node.link : new HyperLink(_node.link)
    _node.link;
  }
}
