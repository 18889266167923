import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Typography } from "./Typography";
import { hyperlink } from "../../classes/Hyperlink";
import { Img } from "../../classes/Img";
import { WmkLink } from "wmk-lib";
import ReactPlayer from "react-player";
import { colors } from "../ui/colorVariables";
import { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";

const PatternBlockContainer = styled(Container)`
  margin: 5vh 0;
  @media only screen and (max-width: 768px) {
    margin: 0;
  }
`;

const TextCardCol = styled(Col)`
  padding: 0;
  .col {
    background: white;
    margin: 5vw;
    padding: 3vh 3vw;
  }
  a {
    color: ${colors.blue.hex};
  }
  h3 {
    font-size: 36px !important;
  }
  @media only screen and (max-width: 1200px) {
    h3 {
      font-size: 30px !important;
    }
  }
  @media only screen and (max-width: 992px) {
    h3 {
      font-size: 26px !important;
    }
  }
`;

const VideoCol = styled(Col)`
  padding-right: 0;
  padding-left: 0;
`;

const MobileTextCardCol = styled(Col)`
  padding: 0;
  .decoration-line {
    height: 3vh;
    width: 100%;
  }
  .col {
    background: white;
    margin: 0;
    padding: 2vh 10vw 4vh 10vw;
  }
  a {
    color: ${colors.blue.hex};
  }
  @media only screen and (max-width: 768px) {
    h3 {
      font-size: 30px !important;
    }
  }
  @media only screen and (max-width: 576px) {
    h3 {
      font-size: 26px !important;
    }
  }
`;

export const getPatternImage = (image: Img, windowWidth: number) => {
  const backgroundImageSrc =
    image?.gatsby?.images?.sources[0]?.srcSet?.split(",");
  const firstBgImgSrc = backgroundImageSrc[0].split(" ");
  const smallBgImgSrc = firstBgImgSrc[0];
  const secondBgImgSrc = backgroundImageSrc[1].split(" ");
  const mediumBgImgSrc = secondBgImgSrc[0];
  const thirdBgImgSrc = backgroundImageSrc[2].split(" ");
  const largeBgImgSrc = thirdBgImgSrc[0];
  return windowWidth > 768 ? largeBgImgSrc : smallBgImgSrc;
};

export const PatternBlockComp = ({
  link,
  linkText,
  media,
  text,
  title,
  patternImage,
  isFirst,
  videoLink
}: {
  link: hyperlink;
  linkText: string;
  media: Img;
  text: string;
  title: string;
  patternImage: Img;
  isFirst: boolean;
  videoLink?: string;
}) => {
  const [videoHeight, setVideoHeight] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);
  const ratioRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const current = ratioRef.current;
    const handleResize = () => {
      const rect = current ? current.getBoundingClientRect() : null;
      // setVideoHeight(get(rect, `height`, (windowWidth / 2) * 0.5625));
      // setVideoWidth(get(rect, `width`, windowWidth / 2));
      setVideoHeight(rect?.height ? rect.height : (windowWidth / 2) * 0.5625);
      setVideoWidth(rect?.width ? rect?.width : windowWidth / 2);
      const winWidth = window.innerWidth;
      setWindowWidth(winWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [videoHeight, videoWidth]);

  const arrow = " >";
  return (
    <PatternBlockContainer fluid>
      {windowWidth > 768 && (
        <Row>
          <TextCardCol
            style={{
              backgroundImage: `url(${getPatternImage(
                patternImage,
                windowWidth
              )})`
            }}
            md={6}
            ref={ratioRef}>
            <Col>
              <Typography.Eyebrow style={{ color: colors.blue.hex, fontSize: "2rem" }}>
                {title}
              </Typography.Eyebrow>
              <Typography.H3><ReactMarkdown children={text}/></Typography.H3>
              {link.to && (
                <WmkLink to={link.to}>
                  <Typography.P>
                    {linkText}
                    {arrow}
                  </Typography.P>
                </WmkLink>
              )}
            </Col>
          </TextCardCol>
          <VideoCol md={6}>
            <ReactPlayer
              url={videoLink ? videoLink : media.src}
              style={{ margin: 0 }}
              width={videoWidth}
              height={videoHeight}
              preload="true"
              controls
              muted
              playing
              loop
            />
          </VideoCol>
        </Row>
      )}
      {windowWidth <= 768 && (
        <>
          <Row>
            <MobileTextCardCol ref={ratioRef}>
              {!isFirst && (
                <div
                  style={{
                    backgroundImage: `url(${getPatternImage(
                      patternImage,
                      windowWidth
                    )})`
                  }}
                  className="decoration-line"></div>
              )}
              <Col>
                <Typography.Eyebrow style={{ color: colors.blue.hex }}>
                  {title}
                </Typography.Eyebrow>
                <Typography.H3>{text}</Typography.H3>
                {link?.to && (
                  <WmkLink to={link.to}>
                    <Typography.P>
                      {linkText}
                      {arrow}
                    </Typography.P>
                  </WmkLink>
                )}
              </Col>
            </MobileTextCardCol>
          </Row>
          <Row>
            <VideoCol>
              <ReactPlayer
                url={videoLink ? videoLink : media.src}
                style={{ margin: 0 }}
                width={windowWidth}
                height={windowWidth * 0.5625}
                preload="true"
                controls
                muted
                playing
                loop
              />
            </VideoCol>
          </Row>
        </>
      )}
    </PatternBlockContainer>
  );
};

// export default PatternBlockComp;
