import { HyperLink, hyperlink } from "./Hyperlink";
import { gatsbyImg, Img } from "./Img";

export interface patternBlock {
  blockId: string;
  link: hyperlink;
  linkText: string;
  media: {
    gatsbyImageData: gatsbyImg["gatsbyImageData"];
    title: string;
    file: {
      url: string;
      contentType: string;
    };
  };
  text: { text: string };
  title: string;
  patternImage: gatsbyImg;
  isFirst?: boolean;
  videoLink?: { url: string };
}

export class PatternBlock {
  blockId: string;
  link: { text?: string; to?: string; target?: string; title: string };
  linkText: string;
  media: Img;
  text: { text: string };
  title: string;
  isFirst?: boolean;
  videoLink?: string;
  constructor(node: patternBlock) {
    const _node = { ...node };
    this.blockId = _node.blockId;
    this.link = new HyperLink(_node.link);
    this.linkText = _node.linkText;
    this.media = new Img(_node.media);
    this.text = _node.text;
    this.title = _node.title;
    this.isFirst = _node.isFirst;
    this.videoLink = _node.videoLink?.url;
  }
}
