import { BLOCKS } from "@contentful/rich-text-types";
import EmbeddedBlock from "./Block";
import * as React from "react";

export const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: (node: unknown) => {
      const entry = new EmbeddedBlock(node);
      return entry.render();
    }
    ,
    [BLOCKS.PARAGRAPH]: (node) => {
      if (node.content[0].value === "") {
        return null
      }
      return <p>{node.content[0].value}</p>
    }
  }
  //renderText: (text) => text.replace("!", "?")
};
