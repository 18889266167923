export interface singleColTextQuery {
  id: string;
  header: string;
  subheader: string;
  text: {
    text: string;
  };
}

export class SingleColText {
  id: string;
  header: string;
  text: { text: string };
  subheader: string;
  constructor(node: singleColTextQuery) {
    const _node = { ...node };
    this.id = _node.id;
    this.header = _node.header;
    this.text = _node.text;
    this.subheader = _node.subheader;
  }
}
