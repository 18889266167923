export interface hyperlink {
  text?: string;
  to?: string;
  target?: string;
  title: string;
  slug?: string;
  parentPath?: string;
  path?: string;
}
/**
 * @class
 * Object that represents exernal hyperlinks
 */
export class HyperLink {
  /**
   * @param {Object} node - result of NodeLinkFields
   */
  text?: string;
  to?: string;
  target?: string;
  title: string;
  constructor(node: hyperlink) {
    const _node = { ...node };
    const _target = _node.target;
    const _text = _node.text;
    const slug = _node.slug;
    const _path = _node.parentPath
      ? _node.parentPath
      : _node.path
      ? _node.path
      : "/";
    // get(_node, `parentPath`, get(_node, `path`, "/"));
    const _to = _node.to;
    const path =
      typeof _path === "string"
        ? _path.indexOf("/") === 0
          ? _path
          : "/" + _path
        : "/";
    const to = _to
      ? _to
      : slug && path !== "/"
      ? `${path}/${slug}`
      : slug && path === "/"
      ? `/${slug}`
      : _path !== "/"
      ? _path
      : undefined;
    const text = _text ? _text : "title" in _node ? _node.title : undefined;
    const isExternal = typeof to === "string" && to.indexOf("http") === 0;
    const target =
      _target === "New Window"
        ? "blank"
        : _target === "Same Window"
        ? "self"
        : isExternal
        ? "blank"
        : undefined;

    this.text = text || undefined;
    this.to = isExternal
      ? to
      : typeof to === "string"
      ? to.replace("//", "/")
      : undefined;
    this.target = target;

    this.title =
      "title" in _node ? _node.title : "name" in _node ? _node.name : undefined;
  }
}
