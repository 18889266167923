import * as React from "react";
import get from "lodash/get";
import { iconsBlock } from "../../classes/IconsBlock";
import styled from "styled-components";
import { Typography } from "./Typography";
import { Col, Container, Row } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import { colors } from "./colorVariables";
import { useState, useEffect } from "react";
import BookIcon from "../Icons/BookIcon";
import PersonIcon from "../Icons/PersonIcon";
import HeartIcon from "../Icons/HeartIcon";

const StyledContainer = styled(Container)`
  background: rgba(${colors.lightGray.rgb}, 0.05);
  padding-bottom: 0;
  @media only screen and (min-width: 769px) {
    width: 98% !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
    padding: 5vh 0;
  }
`;

const StyledTextColumn = styled(Col)`
  h5 {
    margin: 1rem 0;
  }
`;

const StyledMobileColumn = styled(Col)`
  margin: 1rem 2rem;
  margin-top: 1rem !important;
  .icon-col {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0;
  }
  .icon-text {
    padding: 0;
  }
  .icon-row {
    margin: 3rem 0;
  }
  h3 {
    font-size: 18px !important;
    margin: 0 10vw 8vh 0;
  }
  h5 {
    font-size: 19px !important;
    margin: 0.5rem 0 0;
  }
`;

const IconsBlockComp = ({
  // blockId,
  firstIconId,
  firstIconText,
  firstIconTitle,
  headerContainer,
  image,
  secondIconId,
  secondIconText,
  secondIconTitle,
  thirdIconId,
  thirdIconText,
  thirdIconTitle,
  title
}: iconsBlock) => {
  const [windowWidth, setWindowWidth] = useState(1201);
  const [headerSize, setHeaderSize] = useState(44);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setWindowWidth]);

  useEffect(() => {
    const handleResize = () => {
      if (windowWidth > 1200) {
        setHeaderSize(44);
      } else if (windowWidth > 992) {
        setHeaderSize(36);
      } else {
        setHeaderSize(28);
      }
    };
    handleResize();
  }, [windowWidth]);

  const labeledIcons = {
    book: <BookIcon height="50" />,
    heart: <HeartIcon height="50" />,
    person: <PersonIcon height="50" />
  };
  const firstIconDisplay = get(labeledIcons, `${firstIconId}`);
  const secondIconDisplay = get(labeledIcons, `${secondIconId}`);
  const thirdIconDisplay = get(labeledIcons, `${thirdIconId}`);

  const mobileDisplay = (
    <StyledMobileColumn>
      <Typography.Eyebrow
        style={{ color: colors.blue.hex, marginBottom: "0.5rem" }}>
        {title}
      </Typography.Eyebrow>
      <Typography.H3>{get(headerContainer, "header")}</Typography.H3>
      <Row className="icon-row">
        <Col xs={3} className="icon-col">
          {firstIconDisplay}
          <Typography.H5>{firstIconTitle}</Typography.H5>
        </Col>
        <Col xs={{ span: 7, offset: 2 }} className="icon-text">
          <Typography.P>{get(firstIconText, "firstIconText")}</Typography.P>
        </Col>
      </Row>
      <Row className="icon-row">
        <Col xs={3} className="icon-col">
          {secondIconDisplay}
          <Typography.H5>{secondIconTitle}</Typography.H5>
        </Col>
        <Col xs={{ span: 7, offset: 2 }} className="icon-text">
          <Typography.P>{get(secondIconText, "secondIconText")}</Typography.P>
        </Col>
      </Row>
      <Row className="icon-row">
        <Col xs={3} className="icon-col">
          {thirdIconDisplay}
          <Typography.H5>{thirdIconTitle}</Typography.H5>
        </Col>
        <Col xs={{ span: 7, offset: 2 }} className="icon-text">
          <Typography.P>{get(thirdIconText, "thirdIconText")}</Typography.P>
        </Col>
      </Row>
    </StyledMobileColumn>
  );
  const desktopDisplay = (
    <>
      <StyledTextColumn md={{ span: 5, offset: 1 }}>
        <Typography.Eyebrow
          style={{ color: colors.blue.hex, margin: "1rem 0 0.5rem 0" }}>
          {title}
        </Typography.Eyebrow>
        <Typography.H3 style={{ fontSize: headerSize }}>
          {get(headerContainer, "header")}
        </Typography.H3>
        <Row style={{ marginBottom: "3rem" }}>
          <Col md={4} className="icon-column">
            {firstIconDisplay}
            <Typography.H5>{firstIconTitle}</Typography.H5>
            <Typography.P>{get(firstIconText, "firstIconText")}</Typography.P>
          </Col>
          <Col md={4} className="icon-column">
            {secondIconDisplay}
            <Typography.H5>{secondIconTitle}</Typography.H5>
            <Typography.P>{get(secondIconText, "secondIconText")}</Typography.P>
          </Col>
          <Col md={4} className="icon-column">
            {thirdIconDisplay}
            <Typography.H5>{thirdIconTitle}</Typography.H5>
            <Typography.P>{get(thirdIconText, "thirdIconText")}</Typography.P>
          </Col>
        </Row>
      </StyledTextColumn>
      <Col md={6} style={{ paddingRight: 0 }}>
        <GatsbyImage image={get(image, "gatsby")} alt={get(image, "title")} />
      </Col>
    </>
  );

  const getDisplay = () => {
    if (windowWidth > 768) {
      return desktopDisplay;
    } else {
      return mobileDisplay;
    }
  };

  return (
    <>
      <StyledContainer fluid>
        <Row>{getDisplay()}</Row>
      </StyledContainer>
    </>
  );
};

export default IconsBlockComp;
