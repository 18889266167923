import get from "lodash/get";
import { gatsbyImg, Img } from "./Img";

export interface iconsBlock {
  blockId: string;
  firstIconId: string;
  firstIconText: { firstIconText: string };
  firstIconTitle: string;
  headerContainer: { header: string };
  image: gatsbyImg;
  secondIconId: string;
  secondIconText: { secondIconText: string };
  secondIconTitle: string;
  thirdIconId: string;
  thirdIconText: { thirdIconText: string };
  thirdIconTitle: string;
  title: string;
}

export class IconsBlock {
  blockId: string;
  firstIconId: string;
  firstIconText: { firstIconText: string };
  firstIconTitle: string;
  headerContainer: { header: string };
  image: gatsbyImg;
  secondIconId: string;
  secondIconText: { secondIconText: string };
  secondIconTitle: string;
  thirdIconId: string;
  thirdIconText: { thirdIconText: string };
  thirdIconTitle: string;
  title: string;
  constructor(node: iconsBlock) {
    this.blockId = get(node, "blockId");
    this.firstIconId = get(node, "firstIconId");
    this.firstIconText = get(node, "firstIconText");
    this.firstIconTitle = get(node, "firstIconTitle");
    this.secondIconId = get(node, "secondIconId");
    this.secondIconText = get(node, "secondIconText");
    this.secondIconTitle = get(node, "secondIconTitle");
    this.thirdIconId = get(node, "thirdIconId");
    this.thirdIconText = get(node, "thirdIconText");
    this.thirdIconTitle = get(node, "thirdIconTitle");
    this.headerContainer = get(node, "headerContainer");
    this.title = get(node, "title");
    this.image = new Img(get(node, "image"));
  }
}
